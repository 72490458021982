import React, { useState} from "react";
import './app.css';
import card_data from './resource.json';
import OptionsForm from "./OptionsForm";

function App() {
  const [cards, setCards] = useState(JSON.parse(JSON.stringify(card_data)));

  return (<div>
      <OptionsForm cards={cards} setCards={setCards} />
    </div>
  );
}

/*const SAMPLE_FLASHCARDS  = [
  {
      "ID": 1,
      "Question": "Arbitrary Arguments",
      "Answer": "Are like Varags in Java; Indicated by an Asterisk - *",
      "Language": "Python",
      "Difficulty": 1
  },
  {
      "ID": 2,
      "Question": "Try-with-resources",
      "Answer": "In “try” block declaration. Can have multiple resources. Resources must implement Auto-Closeable. They are closed in the opposite order from how they were declared. The compiler generates a “finally” block to close these.",
      "Language": "Java",
      "Difficulty": 2
  },
  {
      "ID": 3,
      "Question": "Protected TypeScript class",
      "Answer": "Accessible from self and inheriting classes.",
      "Language": "TypeScript",
      "Difficulty": 2
  },
  {
      "ID": 4,
      "Question": "Dictionaries and Dictionary Keys",
      "Answer": "Declared as key:value pairs as in { uniquekey: value, otheruniquekey: value }.\nKeys are immutable.\nKeys do not have to all be of the same type.",
      "Language": "Python",
      "Difficulty": 1
  },
];*/

export default App;
