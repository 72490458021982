import React, { useState, useRef } from 'react'
import './app.css';
import FlashCardList from './FlashCardList';

export default function OptionsForm({cards}) {
    const [currentCards, setCurrentCards] = useState(cards); 
    const languageEl = useRef();
    const levelEl = useRef();
    const LANGUAGES = [{
        id: 0,
        name: 'Java'
    },
    {
        id: 1,
        name: 'Python'
    },
    {
        id: 2,
        name: 'C#'
    },
    {
        id: 3,
        name: 'Scala'
    },
    {
        id: 4,
        name: 'JavaScript'
    }
];
function handleSubmit(e){
    e.preventDefault();
    fetchData(e)
    console.log(currentCards);
}

function fetchData(e){
    //console.log(e.target.language.value.toString(),e.target.amount.value.toString(),LANGUAGES[e.target.language.value].name);
    let lang_word = LANGUAGES[e.target.language.value].name;
    setCurrentCards(cards.filter((card)=>card.Language===lang_word.toString()).filter((card)=>card.Difficulty===parseInt(e.target.amount.value.toString())).sort(() => Math.random() - .5));
}

  return (
    <div>
      <div className="head-one"><h1>Welcome to the Coding Cards app!</h1>
        <h2>Here you can test your knowledge of several programming lanuguages.</h2>
       <b>Instructions for use:</b>
        <br/>Choose a Language on the left and a Difficulty Level as well (1 to 3 with 3 as the most difficult).
        <br/>Then click "See Cards." To reshuffle, click "See Cards" again. 
        <br/>To view the answer, click the card. Click again to hide it.
        <br/>This application was built in React. 
        <br/>For a video on how to use this app, visit&nbsp;
        <a href="https://www.casetekdesign.com/videos" target="_blank" rel="noreferrer">CaseTekDesign Videos Page</a>
        <br/>Enjoy! Feedback? Contact me at&nbsp; 
        <a href="https://www.casetekdesign.com/blank-1" target="_blank" rel="noreferrer">CaseTekDesign.com</a> - thanks!</div>
       <br/>
       <form className="header" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="language">Language</label>
          <select id="language" ref={languageEl} >
            {LANGUAGES.map(lanugage => {
              return <option value={lanugage.id} key={lanugage.id}>{lanugage.name}</option>
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="amount">Difficulty Level</label>
          <input type="number" id="amount" min="1" step="1" defaultValue={1} max={3} ref={levelEl} />
        </div>
        <div className="form-group">
          <button className="btn">See Cards</button>
        </div>
      </form>
      <FlashCardList cards={currentCards} />
    </div>
  )
}
