import React from 'react'
import FlashCard from './FlashCard'

export default function FlashCardList({cards}) {

  return (<>
<div className='container'><div className='card-grid'>
    {cards.map(card=> {
        return <FlashCard card={card} key={card.id}/>
    })}
    </div>
    </div>
    </>
  )
}
