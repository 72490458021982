import React, { useState, useEffect, useRef } from 'react';
import './app.css';

export default function FlashCard({card}) {
  const [flip, setFlip] = useState(false);
  const [height, setHeight] = useState('initial')
  const frontEl = useRef();
  const backEl = useRef(); 

  function setMaxHeight() {
    const frontHeight = frontEl.current.getBoundingClientRect().height
    const backHeight = backEl.current.getBoundingClientRect().height
    setHeight(Math.max(frontHeight, backHeight, 100))
  }
  useEffect(setMaxHeight, [card.Question,card.Answer])
  useEffect(() => {
    window.addEventListener('resize', setMaxHeight)
    return () => window.removeEventListener('resize', setMaxHeight)
  }, [])

  return ( <div index={card.id} key={card.id} 
        className={`card ${flip ? 'flip' : ''}`}
        style={{height: height}}
        onClick={()=>{setFlip(!flip)}}>
            <div className='front' ref={frontEl}>
                {card.Question}
            </div>
            <div className='back' ref={backEl}>
                {card.Answer}
            </div></div>
  )
}
